import Bloc from './Bloc.js';
import Types from './Types.js';

export default class FindEnemy extends Bloc {

	constructor (src, ctx) {

		super("findenemy", src, ctx);
		this.f = (src, ins) => {
			var items = src.master.enemies.filter(card => !ins[0] || ins[0](card));
			var item = items.length > 0 ? items[Math.floor(Math.random()*items.length)] : null;
			return [item, item !== null];
		};
		this.types = [Types.entityfilter];
	}
}