import Run from '../model/Run';
import Room from '../model/Room';
import Fight from '../model/Fight';
import Shop from '../model/Shop';
import Enemy from '../model/Enemy';
import Card from '../model/Card';
import Status from '../model/Status';
import Library from '../utility/Library';

let translateData = obj => {

  if (!obj)
    return obj;
  if (typeof obj !== "object")
    return obj;
  if (obj.id)
    return obj.id;
  return "object";
}

let reducer = (state = 0, n) => {

  switch (n.type) {
  case "gamestate": {
    state = Run.build(n.data[0]);
    if (n.data[1])
      state.log = n.data[1].slice();
    break;
  }
  case "gameover": {
    state.over = true;
    break;
  }
  case "victory": {
    state.win = true;
    break;
  }
  case "continue": {
    delete state.over;
    if (state.room.fight)
      state.room.fight.enemies = [];
    break;
  }
  case "storyevent": {
    delete state.win;
    state.storyEvent = true;
    state.background = n.data[1];
    state.audio = n.data[2];
    break;
  }
  case "newfloor": {
    state.floors.push([]);
    if (n.data.length > 1) {
      state.map = n.data[1];
      state.time = Math.max(0, n.data[1].length-1);
    } else {
      state.map = [];
      state.time = 0;
    }
    break;
  }
  case "newroom": {
    let room = new Room(state, n.data[0].model);
    state.floor.push(room);
    break;
  }
  case "campfire": {
    state.campfireChoices = n.data[1];
    break;
  }
  case "roomcleanup": {
    delete state.room.fight;
    break;
  }
  case "newfight": {
    let fight = new Fight(state);
    state.room.fight = fight;
    break;
  }
  case "newshop": {
    let shop = new Shop(state, state.room.model);
    state.room.shop = shop;
    break;
  }
  case "fillshop": {
    let shop = state.room.shop;
    shop.articles = n.data[1].slice();
    break;
  }
  case "shopbuy": {
    let shop = state.room.shop;
    shop.articles[n.data[1]].sold = true;
    break;
  }
  case "droppingitem": {
    state.droppingItem = true;
    break;
  }
  case "droppingrelic": {
    state.droppingRelic = true;
    break;
  }
  case "gainmaxhp": {
    state.champion.gainMaxHp(n.data[1], n.data[2]);
    break;
  }
  case "losemaxhp": {
    state.champion.loseMaxHp(n.data[1], n.data[2]);
    break;
  }
  case "gainexp": {
    state.champion.exp += n.data[1];
    break;
  }
  case "loseexp": {
    state.champion.exp -= n.data[1];
    break;
  }
  case "gaingold": {
    state.champion.gold += n.data[1];
    break;
  }
  case "losegold": {
    state.champion.gold -= n.data[1];
    break;
  }
  case "gaincorruption": {
    state.champion.corruption += n.data[1];
    break;
  }
  case "losecorruption": {
    state.champion.corruption -= n.data[1];
    break;
  }
  case "relic": {
    state.champion.relics.push(n.data[1]);
    break;
  }
  case "item": {
    state.champion.items.push(n.data[1]);
    break;
  }
  case "useitem": {
    state.champion.items.splice(n.data[1], 1);
    delete state.droppingItem;
    break;
  }
  case "droprelic": {
    state.champion.relics.splice(n.data[1], 1);
    delete state.droppingRelic;
    break;
  }
  case "levelup": {
    state.champion.level++;
    state.levelingUp = true;
    state.options = n.data[2];
    break;
  }
  case "choosecampfire": {
    delete state.campfireChoices;
    break;
  }
  case "startrun": {
    state.map.shift();
    break;
  }
  case "pathchoices": {
    state.pathChoices = n.data[1];
    state.map.shift();
    break;
  }
  case "choosepath": {
    delete state.pathChoices;
    break;
  }
  case "dropcardchoice": {
    state.droppingCard = true;
    break;
  }
  case "dropcard": {
    delete state.droppingCard;
    delete state.levelingUp;
    break;
  }
  case "skipcardchoice": {
    delete state.options;
    delete state.choosingCard;
    delete state.droppingCard;
    delete state.levelingUp;
    break;
  }
  case "newcard": {
    new Card(state.room.fight, n.data[2], n.data[0].id.no);
    break;
  }
  case "newenemy": {
    let enemy = new Enemy(state.room.fight, n.data[2]);
    enemy.hp = n.data[0].hp;
    enemy.maxhp = n.data[0].maxhp;
    state.room.fight.enemies.push(enemy);
    break;
  }
  case "intent": {
    let enemy = state.room.fight.find(n.data[0].id);
    enemy.nointent = n.data[1];
    break;
  }
  case "dissipatestatus": {
    let status = state.room.fight.find(n.data[0].id);
    if (status)
      status.dissipate();
    break;
  }
  case "changemana": {
    let card = state.room.fight.find(n.data[0].id);
    card.changeMana(n.data[1]);
    break;
  }
  case "movecard": {
    let card = state.room.fight.find(n.data[0].id);
    let loc = state.room.fight.find(n.data[2].type);
    card.goto(loc);
    break;
  }
  case "addproperty": {
    let card = state.room.fight.find(n.data[0].id);
    card.addProperty(n.data[1]);
    break;
  }
  case "removeproperty": {
    let card = state.room.fight.find(n.data[0].id);
    card.removeProperty(n.data[1]);
    break;
  }
  case "setpvariable": {
    let card = state.room.fight.find(n.data[0].id);
    card.permanentVariables[n.data[1]] = n.data[2];
    break;
  }
  case "lifegain": {
    let entity = state.room?.fight ? state.room.fight.find(n.data[0].id) : state.champion;
    entity.hp += n.data[1];
    break;
  }
  case "blockgain": {
    let entity = state.room.fight.find(n.data[0].id);
    entity.block = (entity.block || 0) + n.data[1];
    break;
  }
  case "lifeloss": {
    let entity = state.room.fight ? state.room.fight.find(n.data[0].id) : state.champion;
    entity.hp -= n.data[2];
    entity.hp = Math.max(0, entity.hp)
    break;
  }
  case "blockloss": {
    let entity = state.room.fight.find(n.data[0].id);
    entity.block -= n.data[2];
    break;
  }
  case "applystatus": {
    let entity = state.room.fight.find(n.data[0].id);
    let existing = entity.statuses.filter(status => status.model.key === n.data[1]);
    if (existing.length > 0)
      existing[0].add(n.data[2], true);
    else
      entity.statuses.push(new Status(entity.master, n.data[1], entity, n.data[2], true));
    break;
  }
  case "addmana": {
    state.room.fight.addMana(n.data[1]);
    break;
  }
  case "paymana": {
    state.room.fight.pay(n.data[1]);
    break;
  }
  case "playerturn": {
    state.room.fight.turncount++;
    state.room.fight.mana = (state.room.fight.player.hasStatus(15) ? state.room.fight.mana : (state.room.fight.player.hasStatus(53) ? Math.min(state.room.fight.mana, state.room.fight.player.getStatusValue(53)) : 0)) + state.room.fight.turnmana;
    state.room.fight.player.update();
    break;
  }
  case "endplayerturn": {
    state.room.fight.player.endturnUpdate();
    break;
  }
  case "enemyturn": {
    state.room.fight.enemies.forEach(enemy => enemy.update());
    break;
  }
  case "endenemyturn": {
    state.room.fight.enemies.forEach(enemy => enemy.endturnUpdate());
    break;
  }
  case "fightloot": {
    state.room.fight.addLoot(Object.assign({}, n.data[1]));
    break;
  }
  case "fightreward": {
    state.room.fight.rewarding = true;
    break;
  }
  case "collectloot": {
    state.room.fight.loot.splice(n.data[1], 1)
    break;
  }
  case "newcardchoice": {
    state.choosingCard = true;
    state.options = n.data[1];
    break;
  }
  case "collectcard": {
    delete state.choosingCard;
    break;
  }
  case "handchoice": {
    state.room.fight.choosingHand = true;
    break;
  }
  case "chosehand": {
    delete state.room.fight.choosingHand;
    break;
  }
  case "addcard": {
    state.deck.push(n.data[1]);
    break;
  }
  case "removecard": {
    state.removeCard(n.data[1]);
    break;
  }
  case "timedown": {
    state.time--;
    break;
  }
  default: break;
  }

  if (typeof state === "object" && n.type !== "gamestate") {
    state.log = state.log || [];
    state.log.push({ type: n.type, data: n.data.map(d => translateData(d)) });
  }

  return state;
}

export default reducer;