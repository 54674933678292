import React, { Component } from 'react';
import Lightbox from '../../components/utility/Lightbox';
import { Tooltip } from 'reactstrap';
import './Reward.css';
import Img from '../../StableImage';

export default class Reward extends Component {

  state = {

    tooltip: null
  }

  toggleTooltip (tooltip, touch) {

    if (tooltip === this.state.tooltip && this.state.tooltipDate && new Date() - this.state.tooltipDate < 500)
      return;

    if (touch) {
      
      if (this.tooltipTimeout)
        clearTimeout(this.tooltipTimeout);

      this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip, tooltipDate: new Date()});
      this.tooltipTimeout = setTimeout(() => this.toggleTooltip(null), 3000);
      return;
    }

    this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip});
  }

  render () {

    let tooltips = []

    return (
      <div className="fight-reward-wrapper">
        <div className="lightbox-container fight-reward">
          <div className="lightbox-inner">
            <div className="fight-reward-champion">
              <Img className="fight-reward-champion-img" src={this.props.src.run.champion.model.portrait[this.props.src.run.champion.level-1]}/>
            </div>
            <div className="fight-reward-info">
              <h1>Reward</h1>
              <div className="fight-loot">
              {
                this.props.src.loot.map((loot, i) =>
                  <div key={i} className="fight-loot-piece" onClick={()=>{}/*() => this.props.collect(i)*/}>
                    {(() => {
                      switch (loot.type) {
                      case "card": return <div className="loot-icon"><Img src="/images/card.png"/><span>New card</span></div>
                      case "gold": return <div className="loot-icon"><Img src="/images/gold.png"/><span>{loot.value} gold</span></div>
                      case "exp": return <div className="loot-icon"><Img src="/images/exp.png"/><span>{loot.value} exp</span></div>
                      case "item":
                        tooltips.push(<Tooltip key={"tooltip-item-" + i} className="tooltip" placement="top" isOpen={this.state.tooltip === "item-" + loot.value.key} target={"loot-item-" + loot.value.key} toggle={() => this.toggleTooltip("item-" + loot.value.key)}>{ loot.value.effect }</Tooltip>)
                        return <div id={"loot-item-" + loot.value.key} className="loot-icon"><Img src={ loot.value.img }/><span>{loot.value.name}</span></div>
                      }
                    })()}
                  </div>
                )
              }
              </div>
              <div className="reward-tooltips">{ tooltips }</div>
              <div className="fight-reward-end" onClick={() => this.props.cleanup()}>Continue</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

