import React, { Component } from 'react';
import './Progress.css';
import Nav from '../../nav/Nav';
import MainButton from '../../buttons/MainButton';
import Link from '../../utility/Link';
import Back from '../../Back';
import StoryText from '../../text/StoryText';
import Library from '../../../scene/utility/Library';
import User from '../../../User';
import Img from '../../../StableImage';
import DeckViewer from '../../../scene/view/DeckViewer';
import { Tooltip } from 'reactstrap';

import { read } from '../../../TextManager';

const statuses = Object.values(Library.statuses);
const champions = Object.values(Library.champions);

const dateoptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
};

export default class Progress extends Component {

  state = {
    champion: User.championSelect,
    difficulty: User.difficulty,
    noRun: null
  }

  toggleTooltip (tooltip, touch) {

    if (touch) {
      if (tooltip === this.state.tooltip && this.state.tooltipDate && new Date() - this.state.tooltipDate < 500)
        return;

      this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip, tooltipDate: new Date()});
      return;
    }

    this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip});
  }

  parseTime (t) {

    let h = Math.floor(t/60);
    let m = t%60;
    let result = "";
    if (h)
      result += h + "h";
    result += m + "m";
    return result
  }

  computeEffect (text) {

    let splits = [];
    // eslint-disable-next-line
    let texts = text.split(/\[[^\[]+\]|\{[^\{]+\}|\n|D:\d+|B:\d+/);
    // eslint-disable-next-line
    let matches = text.match(/\[[^\[]+\]|\{[^\{]+\}|\n|D:\d+|B:\d+/g);
    if (matches)
      matches.forEach((match,i) => {
        let el = match.slice(1, -1);
        splits.push(<span key={i+"a"}>{ texts[i] }</span>);
        if (match[0] === '{') {
          let key = match.substring(1, match.length - 1)
          splits.push(<span>{Library.cards[key].name}</span>);
          /*let slices = el.split('/');
          let key = slices[0];
          let ntoken = this.state.token.slice(); ntoken.push(Library.cards[key]);
          let repeat = this.current.key && key === this.current.key.toString();
          splits.push(<span onClick={repeat ? () => {} : () => this.setState({tooltip: null, token: ntoken})} key={i} className={"token" + (repeat ? " repeat-token" : "")} id={'effect-' + i}>{ slices.length > 1 ? slices[1] : Library.cards[key].name }</span>);*/
        } else if (match[0] === '[') {
          let slices = el.split('/');
          let keyword = slices[0];
          let status = statuses.filter(s => s.name === keyword)[0];
          splits.push(<span key={i} className="keyword" id={'effect-' + i}><Img key={keyword + "-" + i + "-" + this.state.champion.key} className="keyword-icon" src={"/images/statuses/" + keyword + ".png"} alt=""/>{slices.length > 1 ? slices[1] : status.name}</span>);
          //splits.push(<Tooltip key={i+"t"} className="tooltip" placement="top" isOpen={this.state.tooltip === i} target={"effect-" + i} toggle={() => this.toggleTooltip(i)}>{ status.effect }</Tooltip>);
         
        } else if (match[0] === '\n') {
          splits.push(<br key={i}/>);
        } else if (match[0] === 'D') {
          let initialvalue = parseInt(match.substring(2), 10);
          splits.push(<span key={i} className="num-effect damage-effect">{ initialvalue }</span>);
        } else if (match[0] === 'B') {
          let initialvalue = parseInt(match.substring(2), 10);
          splits.push(<span key={i} className="num-effect block-effect">{ initialvalue }</span>);
        }
      });
    splits.push(<span key={"end"}>{ texts[texts.length-1] }</span>);
    return splits;
  }

  embark () {

    User.removeItem("run.save");
    User.removeItem("run.log");
    User.setItem("champion.select", this.state.champion);
  }

  setDifficulty (difficulty) {

    if (this.state.difficulty === difficulty)
      return;
    this.setState({difficulty});
  }

  render () {

    let champion = Library.getChampion(this.state.champion);

    let locked = champion.premium && !User.premium;

    let progress = User.progress[this.state.difficulty][this.state.champion];
    let stats = User.stats[this.state.difficulty][this.state.champion];

    return (
      <div className="main-page progress-page">
        <Nav/>
        <div className="main">
          <div className={"story-background" + (locked ? " locked" : "") }>
            <Img key={champion.key} retries={3} delay={5000} src={champion.fullart}/>
          </div>
          <div className="story-champion">
            <div className="story-champion-name game-effect"><Img key={champion.key} src={"/images/level/" + champion.name + ".png"} className="keyword-icon"/>{champion.name}</div>
            
            <div className="story-stats">
              <div className="story-stat">
                <div>Max level:</div><div>{(progress.level) + " / " + (champion.levelLimitPremium || champion.levelLimit)}</div>
              </div>
              <div className="story-stat">
                <div>Quests completed:</div><div>{(progress.completion) + " / " + ((champion.levelLimitPremium || champion.levelLimit)-1)*2}</div>
              </div>
              <div className="story-stat">
                <div>Runs:</div><div>{stats.runsPlayed}</div>
              </div>
              <div className="story-stat">
                <div>Rooms cleared:</div><div>{stats.roomsCleared}</div>
              </div>
              <div className="story-stat">
                <div>Bosses slain:</div><div>{stats.bossesSlain}</div>
              </div>
              <div className="story-embark" onClick={() => { if (stats.runs.length > 0) this.setState({noRun: stats.runs.length-1}); } }>
                <div>Previous runs</div>
              </div>
            </div>
            
            <div className="difficulty-switch">
                {/*<div id="zen-difficulty" onPointerDown={() => this.toggleTooltip("zen-difficulty")} onClick={() => this.setDifficulty("zen")} className={"difficulty-option zen-option " + (this.state.difficulty === "zen" ? "active" : "")}><Img src="/images/zen.png"/></div>
                <Tooltip className="tooltip" placement="top" isOpen={this.state.tooltip === "zen-difficulty"} target="zen-difficulty" toggle={() => this.toggleTooltip("zen-difficulty")}><b>Zen mode</b><br/>Very high health and no game over. No achievements.</Tooltip>*/}
                <div id="casual-difficulty" onPointerDown={e => e.pointerType === "touch" && this.toggleTooltip("casual-difficulty", true)} onClick={() => this.setDifficulty("casual")} className={"difficulty-option casual-option " + (this.state.difficulty === "casual" ? "active" : "")}><Img src="/images/casual.png"/></div>
                <Tooltip className="tooltip" placement="top" isOpen={this.state.tooltip === "casual-difficulty"} target="casual-difficulty" toggle={() => this.toggleTooltip("casual-difficulty")}><b>Casual mode</b><br/>High health and easy enemies.</Tooltip>
                <div id="challenge-difficulty" onPointerDown={e => e.pointerType === "touch" && this.toggleTooltip("challenge-difficulty", true)} onClick={() => this.setDifficulty("challenge")} className={"difficulty-option challenge-option " + (this.state.difficulty === "challenge" ? "active" : "")}><Img src="/images/challenge.png"/></div>
                <Tooltip className="tooltip" placement="top" isOpen={this.state.tooltip === "challenge-difficulty"} target="challenge-difficulty" toggle={() => this.toggleTooltip("challenge-difficulty")}><b>Challenge mode</b><br/>Low health and hard enemies.</Tooltip>
              </div>
            <div onClick={() => this.setState({champion: this.state.champion == 1 ? champions.length : this.state.champion - 1})} className="cardbox-arrow cardbox-arrow-left"><Img alt="" src="/images/arrowhead.png"/></div>
            <div onClick={() => this.setState({champion: this.state.champion % champions.length + 1})} className="cardbox-arrow cardbox-arrow-right"><Img alt="" src="/images/arrowhead.png"/></div>
          </div>
        </div>
        {
          this.state.noRun !== null ?
          <div className="progress-run">
            <div className="progress-run-date">{ new Date(stats.runs[this.state.noRun].date).toLocaleString('en-US', dateoptions).replace(/,/, '').replace(/(\d{2})$/, '$1') }</div>
            <div className="progress-run-stats">
              <div className="progress-run-stat">{ "Level: " + stats.runs[this.state.noRun].stats.level }</div>
              <div className="progress-run-stat">{ "Rooms cleared: " + stats.runs[this.state.noRun].stats.roomsCleared }</div>
              <div className="progress-run-stat">{ "Bosses slain: " + stats.runs[this.state.noRun].stats.bossesSlain }</div>
            </div>
            <DeckViewer deck={stats.runs[this.state.noRun].deck.map(c => Library.getCard(c))} onClose={() => this.setState({noRun: null})}/>
            { stats.runs.length > this.state.noRun + 1 ? <div onClick={() => this.setState({noRun: this.state.noRun + 1 })} className="cardbox-arrow cardbox-arrow-left"><Img alt="" src="/images/arrowhead.png"/></div> : "" }
            { this.state.noRun > 0 ? <div onClick={() => this.setState({noRun: this.state.noRun - 1 })} className="cardbox-arrow cardbox-arrow-right"><Img alt="" src="/images/arrowhead.png"/></div> : "" }
          </div> : ""
        }
        <Back to="/play"/>
      </div>
    );
  }
}

