import React, { Component } from 'react';
import './Champion.css';
import Entity from './Entity';

export default class Champion extends Component {

  render () {

    return (
      <div className="game-champion">
        <Entity onSelect={this.props.onSelect} src={this.props.src} scale={this.props.src.champion ? this.props.src.champion.model.scale[this.props.src.champion.level-1] : undefined}/>
      </div>
    );
  }
}

