import Library from './scene/utility/Library';

const quests = Library.quests;

function isOlderOrEqual(version1, version2) {
    const v1Parts = version1.split('.').map(Number);
    const v2Parts = version2.split('.').map(Number);

    const maxLength = Math.max(v1Parts.length, v2Parts.length);

    for (let i = 0; i < maxLength; i++) {
        const v1 = v1Parts[i] || 0;
        const v2 = v2Parts[i] || 0;

        if (v1 < v2) {
            return true;
        } else if (v1 > v2) {
            return false;
        }
    }

    return true;
}

export function updateSaveData(savedata) {

	const version = savedata.version;

    if (isOlderOrEqual(version, "0.1")) {
        // Revert progress save bug
        if (savedata.progress) {
            let progress = JSON.parse(savedata.progress);
            Object.keys(progress).forEach(key => {
                progress[key].completion = progress[key].level - 1 + Math.min(1, progress[key].story);
                progress[key].quest = (key-1) * 1000 + 1;
                for (let i = 0; i < progress[key].completion; i++)
                    progress[key].quest = quests[progress[key].quest].next;
                delete progress[key].story;
            })
            savedata.progress = JSON.stringify({"challenge": progress, "casual": progress});
        }
    }

    if (isOlderOrEqual(version, "0.2")) {
        if (savedata.progress) {
            let progress = JSON.parse(savedata.progress);
            progress.zen = progress.casual;
            ["zen", "casual", "challenge"].forEach(diff => {
                progress[diff][4] = {};
                progress[diff][4].level = 1;
                progress[diff][4].quest = 3001;
                progress[diff][4].completion = 0;
            })
            savedata.progress = JSON.stringify(progress);
        }
    }

    if (isOlderOrEqual(version, "0.2")) {

        delete savedata["run.log"];
        delete savedata["run.save"];
    }
}