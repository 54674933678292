import React, { Component } from 'react';
import "./RotateMessage.css";

export default class RotateMessage extends Component {

  render() {

    return (
      <div className="rotate-message-wrapper">
        <img className="rotate-message-image" src="/images/rotate.png"/>
        <div className="rotate-message">
          {"Please rotate your device"}
        </div>
      </div>
    );
  }
}