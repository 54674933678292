import React, { Component } from 'react';
import './Hand.css';
import Card from '../../components/cards/Card';
import Library from '../utility/Library';
import { Tooltip } from 'reactstrap';
import Img from '../../StableImage';

const statuses = Object.values(Library.statuses);

export default class Hand extends Component {

  state = {
    info: null,
    tooltip: null
  }

  componentDidMount () {

    this.resizehandler = () => this.forceUpdate();
    window.addEventListener('resize', this.resizehandler);
  }

  componentWillUnmount () {

    window.removeEventListener('resize', this.resizehandler);
  }

  info (card) {

    if (this.props.targeting)
      return;
    if (this.state.info !== card)
      this.setState({info: card})
  }

  computeEffect (text) {

    if (this.state.info && this.state.info.compute && (!this.props.targeting || this.props.targeting.id.no === this.state.info.id.no)) {
      let compute = this.state.info.compute();
      let computetype = "";
      switch (compute.type) {
      case "attack": computetype = "D:"; break;
      case "block": computetype = "B:"; break;
      default: computetype = "V:"; break;
      }
      text += " (" + computetype + (compute.value > 0 ? compute.value : 0) + ")"
    }

    let splits = [];
    // eslint-disable-next-line
    let texts = text.split(/\[[^\[]+\]|\{[^\{]+\}|\n|D:\d+|B:\d+|V:\d+/);
    // eslint-disable-next-line
    let matches = text.match(/\[[^\[]+\]|\{[^\{]+\}|\n|D:\d+|B:\d+|V:\d+/g);
    if (matches)
      matches.forEach((match,i) => {
        let el = match.slice(1, -1);
        splits.push(<span key={i+"a"}>{ texts[i] }</span>);
        if (match[0] === '{') {
          let key = match.substring(1, match.length - 1)
          splits.push(<span className="token" onClick={() => this.props.onFocus(Library.cards[key])} key={i}>{Library.cards[key].name}</span>);
          /*let slices = el.split('/');
          let key = slices[0];
          let ntoken = this.state.token.slice(); ntoken.push(Library.cards[key]);
          let repeat = this.current.key && key === this.current.key.toString();
          splits.push(<span onClick={repeat ? () => {} : () => this.setState({tooltip: null, token: ntoken})} key={i} className={"token" + (repeat ? " repeat-token" : "")} id={'effect-' + i}>{ slices.length > 1 ? slices[1] : Library.cards[key].name }</span>);*/
        } else if (match[0] === '[') {
          let slices = el.split('/');
          let keyword = slices[0];
          let status = statuses.filter(s => s.name === keyword)[0];
          if (status) {
            splits.push(<span key={i} className="keyword" id={'effect-' + i} onClick={() => this.toggleTooltip(i)}>{status.keyword ? "" : <Img key={keyword + '-' + i} className="keyword-icon" src={"/images/statuses/" + keyword + ".png"} alt=""/>}{slices.length > 1 ? slices[1] : status.name}</span>);
            splits.push(<Tooltip key={i+"t"} className="tooltip" placement="top" isOpen={this.state.tooltip === i} target={"effect-" + i} toggle={() => this.toggleTooltip(i)}>{ status.effect }</Tooltip>);
          }
        } else if (match[0] === '\n') {
          splits.push(<br key={i}/>);
        } else if (match[0] === 'D') {
          let initialvalue = parseInt(match.substring(2), 10);
          let damage = this.props.target ? this.props.target.modifyDamageIn(initialvalue, this.props.src.master.player) : this.props.src.master.player.modifyDamageOut(initialvalue);
          splits.push(<span key={i} className="num-effect damage-effect">{ damage }</span>);
        } else if (match[0] === 'B') {
          let initialvalue = parseInt(match.substring(2), 10);
          let block = this.props.src.master.player.modifyBlock(initialvalue);
          splits.push(<span key={i} className="num-effect block-effect">{ block }</span>);
        } else if (match[0] === 'V') {
          let initialvalue = parseInt(match.substring(2), 10);
          let block = this.props.src.master.player.modifyBlock(initialvalue);
          splits.push(<span key={i} className="num-effect othervalue-effect">{ block }</span>);
        }
      });
    splits.push(<span key={"end"}>{ texts[texts.length-1] }</span>);
    return splits;
  }

  toggleTooltip (tooltip, touch) {

    if (tooltip === this.state.tooltip && this.state.tooltipDate && new Date() - this.state.tooltipDate < 500)
      return;

    if (touch) {
      
      if (this.tooltipTimeout)
        clearTimeout(this.tooltipTimeout);

      this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip, tooltipDate: new Date()});
      this.tooltipTimeout = setTimeout(() => this.toggleTooltip(null), 3000);
      return;
    }

    this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip});
  }

  effectLength (effect) {

    let length = effect.length;
    if (length > 85)
      return "verylong";
    else if (length > 60)
      return "long";
    return "regular";
  }

  render () {

    return (
      <div className="game-hand">
      {
        this.props.targeting || this.state.info ?
        <div className="game-card-info">
          <div className="game-card-info-inner">
            <div className="game-card-info-type">{(this.props.targeting || this.state.info).type}</div>
            <div className={"game-card-info-effect game-effect text-" + this.effectLength((this.props.targeting || this.state.info).model.effect)}>{this.computeEffect((this.props.targeting || this.state.info).model.effect)}</div>
          </div>
        </div> : ""
      }
      {
        this.props.src.cards.map((c,i) => {
          let focus = this.props.focus && this.props.focus === c;
          let center = (this.props.src.cards.length-1)/2;
          var shift = i-center;
          let deg = shift*5;
          let side = shift*10 / (7+this.props.src.cards.length);
          let translate = Math.pow(Math.abs(shift), 1.3)*0.6;
          let style = { transform: "rotateZ(" + deg + "deg) translateY(" + translate + "vw)", left: "calc(50% - 5vw " + (shift >= 0 ? "+" : "-") + " " + Math.abs(side*10) + "vw)" };
          return (
            <div id={"gg-card-" + c.key} key={c.key}
            className="game-hand-card"
            style={style}
            onMouseMove={() => this.info(c)}
            onPointerDown={e => {
              if (e.pointerType === "touch")
                this.info(c);
              if (this.props.hidden)
                return;
              this.props.onSelect(e, c); 
            }}>
              <div className={"game-card-wrapper " + this.props.getHalo(c) + (focus ? " card-focus" : "") + (c.locked ? " card-locked" : "")}>
                <Card src={c}/>
                { !this.props.hidden && c.volatile ? <div className="card-volatile"/> : "" }
              </div>
            </div>
          );
        })
      }
      </div>
    );
  }
}

