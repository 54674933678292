import React, { Component } from 'react';
import './Gallery.css';
import Nav from '../../nav/Nav';
import Back from '../../Back';
import User from '../../../User';
import Img from '../../../StableImage';
import Library from '../../../scene/utility/Library';
import characters from '../../../library/images.json';
import CutsceneManager from '../../../scene/CutsceneManager';
import Cutscene from '../../../scene/view/Cutscene';
import allcards from '../../../library/cards.json';
import allcutscenes from '../../../library/cutscenes.json';
import allimages from '../../../library/gallery.json';

import { read } from '../../../TextManager';

const champions = Library.champions;

function getCupSize(cdInCm) {
    if (cdInCm < 2.5) return "AA";

    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const index = Math.floor((cdInCm - 2.5) / 2.5);
    return letters[index];
}

export default class Gallery extends Component {

  cutsceneManager = new CutsceneManager(null, c => this.setState({cutscene: c ? Object.assign({}, this.state.cutscene, c) : null}))

  state = {
    type: "portraits",
    champion: 1,
    cutscene: null
  }

  render () {

    let premium = User.premium;

    let cutscenes = Object.values(allcutscenes).filter(cutscene => cutscene.champion === -1 || cutscene.champion === this.state.champion && (!isNaN(cutscene.value) || !cutscene.value.endsWith("fast"))).sort((a, b) => a.key - b.key);

    let images = this.state.type === "scenes" || this.state.type === "cards" ? null : Object.values(allimages[this.state.champion][this.state.type]).sort((a, b) => a.order - b.order);

    if (this.state.type === "cards")
      images = Object.values(allcards).filter(c => c.champion === this.state.champion && !c.token).map(c => {
        return {
          title: c.name,
          key: c.key,
          order: c.key,
          img: c.img,
          check: c.level === 1 ? undefined : { level: c.level }
        }
      })

    if (images)
      images.forEach(image => image.unlocked = !image.check || Object.keys(image.check).every(key => {
        let value = image.check[key];//return true;
        switch (key) {
        case "hasSeen": return User.hasSeen(value);
        case "level": return User.progress.casual[this.state.champion].level >= value;
        default: return false;
        }
      }))

    let unlockedImages = [];
    if (this.state.type !== "scenes")
      unlockedImages = images.filter(i => i.unlocked);

    let left = null;
    let right = null;

    if (this.state.nofocus !== null && this.state.nofocus !== undefined) {

      const unlockedIndex = this.state.nofocus;
      if (unlockedIndex > 0) {
        left = images.filter(i => unlockedImages[unlockedIndex-1].key === i.key)[0];
      }
      if (unlockedIndex < unlockedImages.length-1) {
        right = images.filter(i => unlockedImages[unlockedIndex+1].key === i.key)[0];
      }
    }

    return (
      <div className="main-page gallery-page">
        <Nav/>
        {
          this.state.focus && 
          <div className="lightbox-container">
            <div className="lightbox-inner" onClick={() => this.setState({focus: null, nofocus: null})}>
              <div className="gallery-lightbox">
                <div className="gallery-lightbox-description">
                  <div className="gallery-description-section gallery-image-title">{this.state.focus.title}</div>
                  <div className="gallery-description-section"><b>Champion:&nbsp;</b>{champions[this.state.champion].name}</div>
                  { this.state.focus.requirement ? <div className="gallery-description-section"><b>Requirement:&nbsp;</b>{this.state.focus.requirement}</div> : "" }
                  { this.state.focus.height ? <div className="gallery-description-section"><b>Height:&nbsp;</b>{(this.state.focus.height >= 100 ? (this.state.focus.height/100) + "m" : this.state.focus.height + "cm") + " / " + Math.floor(this.state.focus.height * 0.0328084) + "'" + Math.round((this.state.focus.height * 0.0328084 - Math.floor(this.state.focus.height * 0.0328084)) * 12) + "\""}</div> : "" }
                  { this.state.focus.dick ? <div className="gallery-description-section"><b>Dick size:&nbsp;</b>{(this.state.focus.dick >= 100 ? (this.state.focus.dick/100) + "m" : this.state.focus.dick + "cm") + " / " + Math.floor(this.state.focus.dick * 0.0328084) + "'" + Math.round((this.state.focus.dick * 0.0328084 - Math.floor(this.state.focus.dick * 0.0328084)) * 12) + "\""}</div> : "" }
                  { this.state.focus.bust ? <div className="gallery-description-section"><b>Bust size:&nbsp;</b>{(this.state.focus.bust >= 100 ? (this.state.focus.bust/100) + "m" : this.state.focus.bust + "cm") + " / " + Math.floor(this.state.focus.bust * 0.0328084) + "'" + Math.round((this.state.focus.bust * 0.0328084 - Math.floor(this.state.focus.bust * 0.0328084)) * 12) + "\"" + (getCupSize(this.state.focus.bust) ? " (" + getCupSize(this.state.focus.bust) + ")" : "")}</div> : "" }
                  { this.state.focus.lift ? <div className="gallery-description-section"><b>Deadlift:&nbsp;</b>{(this.state.focus.lift) + "kg" + " / " + Math.floor(this.state.focus.lift * 2.20462) + "lbs"}</div> : "" }
                  <div className="gallery-description-section description">{this.state.focus.description}</div>
                </div>
                <div className="gallery-lightbox-image">
                  <Img key={Array.isArray(this.state.focus.img) ? this.state.focus.img[this.state.imgfocus] : this.state.focus.img} src={Array.isArray(this.state.focus.img) ? this.state.focus.img[this.state.imgfocus] : this.state.focus.img}/>
                  {
                    Array.isArray(this.state.focus.img) &&
                    <div className="gallery-lightbox-select-emotion">
                    {
                      this.state.focus.img.map((img, i) => <div key={"emotion-" + i} className={"gallery-lightbox-option-emotion" + (this.state.imgfocus === i ? " selected" : "")} onClick={e => {this.setState({imgfocus: i}); e.stopPropagation(); }}/>)
                    }
                    </div>
                  }
                </div>
                { left ? <div onClick={e => {this.setState({focus: left, nofocus: this.state.nofocus-1, imgfocus: 0}); e.stopPropagation(); }} className="cardbox-arrow cardbox-arrow-left"><Img alt="" src="/images/arrowhead.png"/></div> : "" }
                { right ? <div onClick={e => {this.setState({focus: right, nofocus: this.state.nofocus+1, imgfocus: 0}); e.stopPropagation(); }} className="cardbox-arrow cardbox-arrow-right"><Img alt="" src="/images/arrowhead.png"/></div> : "" }
              </div>
            </div>
          </div>
        }
        <div className="main">
          <div className="cards-page-nav"/>
          <div className="gallery">
            <div className="gallery-sidebar">
              <div className="gallery-sidebar-inner">
                <div className="gallery-champion-select gallery-type-select">
                {
                  ["portraits", "cards", "scenes"].map(type =>
                    <div key={type} className={"gallery-champion-option" + (type === this.state.type ? " selected" : "")} onClick={() => this.setState({type})}>
                      <Img src={"/images/" + type + ".png"}/>
                    </div>
                  )
                }
                </div>
                <div className="gallery-champion-select">
                {
                  Object.values(champions).filter(champion => premium ? true : !champion.premium).map(champion =>
                    <div key={"champion-" + champion.key} className={"gallery-champion-option" + (champion.key === this.state.champion ? " selected" : "")} onClick={() => this.setState({champion: champion.key})}>
                      <Img src={"/images/level/" + champion.name + ".png"}/>
                    </div>
                  )
                }
                </div>
              </div>
            </div>
            <div className="gallery-main">
              <div className="gallery-image-list">
                {
                  this.state.type === "scenes" ?
                    cutscenes.map((cutscene, i) =>
                      <div
                      key={"champion-" + this.state.champion + "-image-" + this.state.type + "-" + cutscene.key}
                      className={"gallery-video gallery-image" + (User.hasSeen(cutscene.key) ? "" : " locked")}
                      onClick={User.hasSeen(cutscene.key) ? () => {
                        this.cutsceneManager.callback = () => this.setState({cutscene: null})
                        this.cutsceneManager.start(cutscene);
                      } : () => {}}
                      >
                        { User.hasSeen(cutscene.key) ?
                          <div>
                            { cutscene.name && <div className="gallery-image-name">{cutscene.name}</div> }
                            <Img src={characters[cutscene.character] || "/images/locked.png"}/>
                          </div> :
                          <div id={cutscene.name}>
                            { cutscene.requirement && <div className="gallery-image-requirement">{cutscene.requirement}</div> }
                            <Img src="/images/locked.png"/>
                          </div>
                        }
                      </div>
                    )
                    /*cutscenes.map(cutscene =>
                      <div key={cutscene.key} className="gallery-cutscene"
                      onClick={() => {
                        this.cutsceneManager.callback = () => this.setState({cutscene: null})
                        this.cutsceneManager.start(cutscene);
                      }
                      }>
                      { cutscene.name }
                      </div>
                    )*/:
                  images.map((image, i) =>
                    <div
                    key={"champion-" + this.state.champion + "-image-" + this.state.type + "-" + image.key}
                    className={"gallery-image" + (image.unlocked ? "" : " locked")}
                    onClick={image.unlocked ? () => this.setState({focus: image, nofocus: unlockedImages.findIndex(im => image.key === im.key), imgfocus: 0}) : () => {}}
                    >
                      { image.unlocked ?
                        <Img src={Array.isArray(image.img) ? image.img[0] : image.img}/> :
                        <div>
                          { image.requirement && <div className="gallery-image-requirement">{image.requirement}</div> }
                          <Img src="/images/locked.png"/>
                        </div>
                      }
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        {/*
          <div className="gallery">
            {
              cutscenes.map(cutscene =>
                <div key={cutscene.key} className="gallery-cutscene"
                onClick={() => {
                  this.cutsceneManager.callback = () => this.setState({cutscene: null})
                  this.cutsceneManager.start(cutscene);
                }
                }>
                { cutscene.name }
                </div>
              )
            }
          </div>
          */}
          {
            this.state.cutscene ?
            <Cutscene back={() => this.cutsceneManager.back()} skip={() => this.cutsceneManager.skip()} continue={() => this.cutsceneManager.continue()} src={this.state.cutscene}/> : ""
          }
        </div>
        <Back to="/play"/>
      </div>
    );
  }
}

