
import Library from '../utility/Library';

const mystery_articles = {
	"relic": {
		"key": -1,
		"name": "Mysterious equipment",
		"img": "https://i.imgur.com/PaGV7H8.png",
		"effect": "This box contains an unknown equipment."
	},
	"item": {
		"key": -1,
		"name": "Mysterious item",
		"img": "https://i.imgur.com/EKjuw5z.png",
		"effect": "This box contains an unknown item."
	},
	"card": {
		"key": -1,
		"name": "Mysterious card",
		"img": "https://i.imgur.com/DyGGFLN.png",
		"effect": "This box contains an unknown card.",
		"type": "item"
	}
}

export default class Shop {

	constructor (run, model) {

		this.run = run;
		this.model = model;
		this.articles = [];
		this.run.notify('newshop', this, run);
	}

	get currency () {

		return this.model.currency || "gold";
	}

	get merchant () {

		return {
			id: this.model.key,
			name: this.model.merchant,
			img: this.model.img
		}
	}

	fill () {

		this.articles = [];

		if (this.model.articles.cards) {

			let cardlist = Object.values(Library.cards).filter(c => (c.level === this.run.champion.level) && c.champion === this.run.champion.model.key);
			let cardlistWeak = this.run.champion.level <= 1 ? [] : Object.values(Library.cards).filter(c => (c.level === this.run.champion.level-1) && c.champion === this.run.champion.model.key);
			for (let i = 0; i < this.model.articles.cards; i++) {

				let possibleCards = cardlist.filter(c => !this.articles.some(a => a.type === "card" && a.value.key === c.key));
				let possibleWeakCards = cardlistWeak.filter(c => !this.articles.some(a => a.type === "card" && a.value.key === c.key));
				let value = this.run.champion.level <= 1 || Math.random() < 0.65 ? possibleCards[Math.floor(Math.random() * possibleCards.length)] : possibleWeakCards[Math.floor(Math.random() * possibleWeakCards.length)];
				let price = Math.ceil(value.level * 30 * (0.8 + Math.random() * 0.7) * (this.model.price || 1) * (this.run.challenge ? 1.2 : 1))
				this.articles.push({ value, price, type: "card" });
			}
		}

		if (this.model.articles.items) {

			let itemlist = Object.values(Library.items).filter(i => this.model.pools.items.includes(i.key) && (!i.champion || i.champion === this.run.champion.model.key || Array.isArray(i.champion) && i.champion.includes(this.run.champion.model.key)));
			for (let i = 0; i < this.model.articles.items; i++) {

				let possibleItems = this.model.duplicates ? itemlist : itemlist.filter(i => !this.articles.some(a => a.type === "item" && a.value.key === i.key));
				let value = possibleItems[Math.floor(Math.random() * possibleItems.length)];
				let price = Math.ceil(value.points * 4 * (0.8 + Math.random() * 0.7) * (this.model.price || 1) * (this.run.challenge ? 1.2 : 1))
				this.articles.push({ value, price, type: "item" });
			}
		}

		if (this.model.articles.relics) {

			let reliclist = Object.values(Library.relics).filter(i => this.model.pools.relics.includes(i.key) && (!i.champion || i.champion === this.run.champion.model.key));
			for (let i = 0; i < this.model.articles.relics; i++) {

				let possibleRelics = reliclist.filter(i => !this.articles.some(a => a.type === "relic" && a.value.key === i.key) && !this.run.champion.relics.some(r => r.key === i.key));
				if (possibleRelics.length > 0) {
					let value = possibleRelics[Math.floor(Math.random() * possibleRelics.length)];
					let price = Math.ceil(value.points * 4 * (0.8 + Math.random() * 0.7) * (this.model.price || 1) * (this.run.challenge ? 1.2 : 1))
					this.articles.push({ value, price, type: "relic" });
				} else {
					this.articles.push({ sold: true, price: 0 })
				}
			}
		}

		if (this.model.mystery)
			this.articles = this.articles.map(a => Object.assign({}, a, { value: mystery_articles[a.type], contains: a.value, mystery: true }));

		this.run.notify('fillshop', this, this.articles);
	}

	buy (no) {

		if (no >= this.articles.length)
			return;
		let article = this.articles[no];

		switch (this.currency) {
		case "health":
			if (article.sold || article.price > this.run.champion.hp)
				return;
			this.run.champion.loseLife(article.price);
			break;
		default:
			if (article.sold || article.price > this.run.champion.gold)
				return;
			this.run.champion.loseGold(article.price);
			break;
		}

		article.sold = true;

		let value = article.mystery ? article.contains : article.value;

		switch (article.type) {
		case "card": this.run.addCard(value); break;
		case "item": this.run.champion.newItem(value); break;
		case "relic": this.run.champion.newRelic(value); break;
		default: break;
		}

		this.run.notify('shopbuy', this, no);
	}

	serialize () {

		return {
			articles: this.articles.slice()
		}
	}

	static build (run, src, model) {

		let shop = new Shop(run, model);
		shop.articles = src.articles.slice();
		return shop;
	}
}