
export default class PlayingState {

	constructor (scene, fight) {

		this.scene = scene;
		this.fight = fight;
		this.name = "playing";
	}

	selectCard (card, touch) {

		let model = this.fight.find(card.id);
		if (!model.canBePlayed)
		  return;
		if (model.hasTarget)
		  this.selected = model;
		else if (touch && (!this.selected || this.selected !== model))
			this.selected = model;
		else if (this.selected && this.selected.hasTarget)
			delete this.selected;
		else {
		  model.play();
		  delete this.selected;
		  if (!this.fight.done)
		  	this.scene.save();
		}
	}

	selectEntity (entity) {

		if (this.selected && !this.selected.hasTarget) {
			this.unselect();
			return;
		}

		if (this.selected)
		  if (this.selected.canBePlayed && this.selected.canTarget(this.fight.find(entity.id))) {
		    this.selected.play(this.fight.find(entity.id));
		    delete this.selected;
			  if (!this.fight.done)
			  	this.scene.save();
		  }
	}

  endTurn () {

    this.fight.endPlayerTurn();
  }

  cardHalo (card) {

		let model = this.fight.find(card.id);
		if (this.selected && model === this.selected)
			return "selected-halo";
		if (model.canBePlayed)
		  return "active-halo";
		return;
  }

  unselect () {

  	delete this.selected;
  }
}