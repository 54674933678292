import Library from '../utility/Library';
import User from '../../User';
import Reader from './blueprint/Reader';

const MAX_RELICS = 2;
const MAX_ITEMS = 4;

export default class Champion {

	constructor (run, model, level=1) {

		this.run = run;
		this.model = Library.getChampion(model);
		this.hp = this.model.hp * (this.run.zen ? (10/4) : (this.run.casual ? (5/4) : 1));
		this.maxhp = this.model.hp * (this.run.zen ? (10/4) : (this.run.casual ? (5/4) : 1));
		if (level > 1) {
			for (let i = 2; i < level+1; i++) {
				this.hp += level * 40  * (this.run.zen ? (10/4) : (this.run.casual ? (5/4) : 1));
				this.maxhp += level * 40  * (this.run.zen ? (10/4) : (this.run.casual ? (5/4) : 1));
			}
		}
		this.exp = 0;
		this.level = level;
		this.maxLevel = Math.min(User.premium && this.model.levelLimitPremium ? this.model.levelLimitPremium : this.model.levelLimit, User.progress[this.run.difficultySetting][this.model.key].level);
		this.gold = (level-1) * 100;
		this.corruption = 0;
		this.relics = [];
		this.items = [];
	}

	get id () { return "champion"; }

	get img () { return this.model.img[this.level-1] }

	get threshold () {

		return (this.run.casual || this.run.zen ? this.model.thresholdsCasual : this.model.thresholds)[this.level-1];
	}

	checkLevelUp () {

		if (this.exp < this.threshold)
			return false;
		if (this.level >= this.maxLevel)
			return false;

		this.loseExp(this.level + 1 === this.maxLevel ? this.exp : this.threshold);
		this.levelUp();

		return true;
	}

	levelUp () {

		this.level++;
		let options = this.run.levelUp();
		this.gainMaxHp(this.level * 40  * (this.run.zen ? (10/4) : (this.run.casual ? (5/4) : 1)), false);
		this.heal(this.level * 20  * (this.run.zen ? (10/4) : (this.run.casual ? (5/4) : 1)), false);
		this.run.notify('levelup', this, this.level, options);
	}

	gainExp (amt) {

		if (this.level >= this.maxLevel)
			return;
		this.exp += amt;
		this.run.notify('gainexp', this, amt);
	}

	loseExp (amt) {

		this.exp -= amt;
		this.run.notify('loseexp', this, amt);
	}

	gainGold (amt) {

		this.gold += amt;
		this.run.notify('gaingold', this, amt);
	}

	loseGold (amt) {

		amt = Math.min(this.gold, amt);
		if (amt <= 0)
			return;

		this.gold -= amt;
		this.run.notify('losegold', this, amt);
	}

	gainCorruption (amt) {

		this.corruption += amt;
		this.run.notify('gaincorruption', this, amt);
	}

	loseCorruption (amt) {

		amt = Math.max(0, this.corruption - amt);
		if (amt <= 0)
			return;

		this.corruption -= amt;
		this.run.notify('losecorruption', this, amt);
	}

	heal (amt) {

		amt = Math.min(amt, this.maxhp - this.hp);
		
		if (!amt || amt < 0)
			return;

		this.hp += amt;
		this.run.notify('lifegain', this, amt);
	}

	loseLife (amt) {

		if (!amt || amt < 0)
			return;

		this.hp -= amt;
		this.run.notify('lifeloss', this, null, amt);
	}

	gainMaxHp (amt, heal=true) {

		this.maxhp += amt;
		if (heal)
			this.hp += amt;
		this.run.notify('gainmaxhp', this, amt,heal);
	}

	loseMaxHp (amt) {

		this.maxhp -= amt;
		this.hp = Math.min(this.hp, this.maxhp)
		this.run.notify('losemaxhp', this, amt);
	}

	newRelic (relic) {

		this.relics.push(relic);

		if (relic.pickup) {
			if (relic.pickup.hp)
				this.heal(relic.pickup.hp);
			if (relic.pickup.maxhp)
				this.gainMaxHp(relic.pickup.maxhp);
		}

		this.run.notify('relic', this, relic);

		if (this.relics.length > MAX_RELICS) {
			this.run.droppingRelic = true;
			this.run.notify('droppingrelic', this);
		}
	}

	dropRelic (no) {

		this.relics.splice(no, 1);
		delete this.run.droppingRelic;
		this.run.notify('droprelic', this, no);
	}

	useItem (no) {

		if (this.items.length <= no)
			return;

		let item = this.items[no];

		if (item.fight && (!this.run.room.fight || this.run.room.fight.done)) {
			if (this.run.droppingItem) {
				this.items.splice(no, 1);
				delete this.run.droppingItem;
				this.run.notify('useitem', this, no);
			}
			return;
		}

		if (item.bonus) {
			if (item.bonus.exp)
				this.gainExp(item.bonus.exp);
			if (item.bonus.hp)
				this.heal(item.bonus.hp);
			if (item.bonus.maxhp)
				this.gainMaxHp(item.bonus.maxhp);
		}

		if (item.blueprint) {

			let fightitem = { master: this.run.room.fight }
			Reader.read(item.blueprint, fightitem);
			if (fightitem.events)
				fightitem.events.forEach(e => e());
		}

		this.items.splice(no, 1);
		delete this.run.droppingItem;
		this.run.notify('useitem', this, no);
	}

	newItem (item) {

		this.items.push(item);

		this.run.notify('item', this, item);

		if (this.items.length > MAX_ITEMS) {
			this.run.droppingItem = true;
			this.run.notify('droppingitem', this);
		}
	}

	serialize () {

		return {
			model: this.model.key,
			maxhp: this.maxhp,
			hp: this.hp,
			exp: this.exp,
			level: this.level,
			maxLevel: this.maxLevel,
			gold: this.gold,
			corruption: this.corruption,
			relics: this.relics.map(relic => relic.key),
			items: this.items.map(item => item.key)
		}
	}

	static build (run, src) {

		let champion = new Champion(run, src.model);
		champion.maxhp = src.maxhp;
		champion.hp = src.hp;
		champion.exp = src.exp;
		champion.level = src.level;
		champion.maxLevel = src.maxLevel;
		champion.gold = src.gold;
		champion.corruption = src.corruption;
		champion.relics = src.relics.map(relic => Library.getRelic(relic));
		champion.items = src.items.map(item => Library.getItem(item));
		return champion;
	}
}