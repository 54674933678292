import React, { Component } from 'react';
import './Entity.css';
import Library from '../utility/Library';
import { Tooltip } from 'reactstrap';
import Img from '../../StableImage';

export default class Entity extends Component {

  state = { tooltip: null }

  toggleTooltip (tooltip, touch) {

    if (tooltip === this.state.tooltip && this.state.tooltipDate && new Date() - this.state.tooltipDate < 500)
      return;

    if (touch) {
      
      if (this.tooltipTimeout)
        clearTimeout(this.tooltipTimeout);

      this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip, tooltipDate: new Date()});
      this.tooltipTimeout = setTimeout(() => this.toggleTooltip(null), 3000);
      return;
    }

    this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip});
  }

  intentText (intent) {

    switch (intent) {
    case "attack": return "deal damage";
    case "block": return "gain some block";
    case "buff": return "gain a positive status";
    case "debuff": return "apply a negative status";
    case "curse": return "add cards to your deck";
    case "heal": return "heal their party";
    case "explode": return "explode";
    case "wait": return "do nothing";
    default: return "[?]";
    }
  }

  render () {

    let statuses = this.props.src.statuses || [];

    let compute = this.props.src.intent && this.props.src.compute ? this.props.src.compute(this.props.src.nointent).value : undefined;

    let invincibility = statuses.filter(s => s.model.key === 42).length > 0;

    let scale = this.props.scale;

    return (
      <div
        key={"game-entity-" + (this.props.src.id === "player" ? "player" : this.props.src.id.no)}
        id={"game-entity-" + (this.props.src.id === "player" ? "player" : this.props.src.id.no)}
        className={"game-entity " + (statuses.reduce((acc, e) => acc + "status-" + e.model.name.toLowerCase() + " ", "")) + (this.props.src.id?.type === "enemy" ? "size-" + (this.props.src.size || "small") : "")}
        >
        <div className="game-entity-main-wrapper">
          <div className="game-entity-main">
            <div className="game-entity-img-wrapper"
                onClick={e => { this.props.onSelect(e, this.props.src); }}
                style={scale ? {height: (33.6 * scale) + "vh", marginTop: (33.6 - 33.6 * scale) + "vh"} : undefined}
                >
              <Img key={this.props.src.id + "-entity-" + this.props.src.img} className="game-entity-main-img" src={this.props.src.img}/>
              <div className="game-entity-shadow"
                style={scale ? {heigh: (7.2 * scale) + "vh", width: (24 * scale) + "vh", left: (4.8 * scale) + "vh"} : undefined}
                />
              <div className="game-entity-afflict-status">
                <Img className="game-entity-afflict-status-img" src="/images/nothing.png"/>
                <div className="game-entity-afflict-status-text"></div>
              </div>
              <div className="game-entity-afflict-status">
                <Img className="game-entity-afflict-status-img" src="/images/nothing.png"/>
                <div className="game-entity-afflict-status-text"></div>
              </div>
              <div className="game-entity-damage"></div>
              <div className="game-entity-damage"></div>
            </div>
            {
              this.props.src.intent ?
              <div id={"entity-" + this.props.src.id.no + "-intent"} onPointerDown={e => this.toggleTooltip(this.props.src.id.no + "-intent", e.pointerType === "touch")} className="game-entity-intent">
                { typeof this.props.src.intent.type === "string" ? "" : <img className="game-entity-intent-img" src={"/images/intents/" + this.props.src.intent.type[1] + ".png"}/> }
                { typeof this.props.src.intent.type === "string" ? "" : <img className="game-entity-intent-img intent-anim-img" src={"/images/intents/" + this.props.src.intent.type[1] + ".png"}/> }
                { typeof this.props.src.intent.type === "string" ? "" : <img className="game-entity-intent-img intent-anim-img" src={"/images/intents/" + this.props.src.intent.type[1] + ".png"}/> }
                <img className="game-entity-intent-img" src={"/images/intents/" + (typeof this.props.src.intent.type === "string" ? this.props.src.intent.type : this.props.src.intent.type[0]) + ".png"}/>
                <img className="game-entity-intent-img intent-anim-img" src={"/images/intents/" + (typeof this.props.src.intent.type === "string" ? this.props.src.intent.type : this.props.src.intent.type[0]) + ".png"}/>
                <img className="game-entity-intent-img intent-anim-img" src={"/images/intents/" + (typeof this.props.src.intent.type === "string" ? this.props.src.intent.type : this.props.src.intent.type[0]) + ".png"}/>
                { this.props.src.intent.damage !== undefined ? <div className="game-entity-intent-text">{ this.props.src.master.player.modifyDamageIn(compute !== undefined ? compute : this.props.src.intent.damage, this.props.src) }{ (this.props.src.intent.times ? <span className="intent-times">{"x" + this.props.src.intent.times}</span> : "") }</div> : "" }
              </div> : ""
            }
            { 
              this.props.src.intent ? <Tooltip className="tooltip" placement="top" isOpen={this.state.tooltip === this.props.src.id.no + "-intent"} target={"entity-" + this.props.src.id.no + "-intent"} toggle={() => this.toggleTooltip(this.props.src.id.no + "-intent")}>
              {(() => {
                return "This enemy intends to " + (typeof this.props.src.intent.type === "string" ? this.intentText(this.props.src.intent.type) : this.intentText(this.props.src.intent.type[0]) + " and " + this.intentText(this.props.src.intent.type[1])) + ".";
              })()}
              </Tooltip> : ""
            }
          <div className="game-entity-name">{this.props.src.name || this.props.src.model.name}</div>
          </div>
        </div>
        {
          this.props.src.maxhp !== undefined ?
          <div className="game-entity-life-wrapper"
          style={scale ? { width: (30 + 8.4 * scale) + "vh" } : undefined}
          >
          {
            this.props.src.block > 0 || invincibility ?
            <div className="game-entity-block">
              <Img className="game-entity-block-img" src={invincibility ? "/images/statuses/Invincibility.png" : "/images/block.png"}/>
              { invincibility ? "" : <div className="game-entity-block-text">{this.props.src.block}</div> }
            </div> : ""
          }
            <div className="game-entity-life-text"
          style={scale ? { width: (30 + 8.4 * scale) + "vh" } : undefined}>
              {this.props.src.hp}/{this.props.src.maxhp}
            </div>
            <div className={"game-entity-life" + (invincibility ? " invincibility" : (this.props.src.block > 0 ? " block" : ""))}
          style={scale ? { width: (30 + 8.4 * scale) + "vh" } : undefined}>
              <div className="game-entity-life-fill" style={{width: (100 * this.props.src.hp / this.props.src.maxhp) + "%"}}/>
              { this.props.src.hasStatus && this.props.src.hasStatus(40) && <div className="game-entity-life-fill poison" style={{width: "calc(1px + " + (100 * this.props.src.getStatusValue(40) / this.props.src.maxhp) + "%)"}}/> }
            </div>
          </div> : ""
        }
        <div className="game-entity-statuses"
          style={scale ? { width: (30 + 8.4 * scale) + "vh" } : undefined}>
        {
          statuses.length > 0 ? statuses.map((status,i) =>
            <div id={"status-" + status.key} key={status.key} className="game-entity-status-wrapper">
              <div className="game-entity-status" onPointerDown={e => this.toggleTooltip(status.key + "-status", e.pointerType === "touch")}>
                <Img className="game-entity-status-img" src={"/images/statuses/" + status.model.name + ".png"}/>
                { !status.model.unstackable && <div className="game-entity-status-text">{status.value}</div> }
              </div>
              <Tooltip className="tooltip" placement="top" isOpen={this.state.tooltip === status.key + "-status"} target={"status-" + status.key} toggle={() => this.toggleTooltip(status.key + "-status")}><b>{ status.model.name }</b><br/>{ status.model.effect }</Tooltip>
            </div>
          ) : <div key="0" className="game-entity-status"/>
        }
        </div>
      </div>
    );
  }
}

