import Library from '../utility/Library';
import Reader from './blueprint/Reader';

export default class Status {

	constructor (master, model, entity, value, visual) {

		master.register(this, "status");
		this.model = Library.getStatus(model);
		this.value = value;

		if (this.model.key === 54) {
			let mana = Math.floor(this.value / 3);
			if (mana > 0) {
				this.value -= mana * 3;
				if (!visual)
					this.master.addMana(mana);
			}
		}

		this.entity = entity;

		if (this.model.blueprint) {
			Reader.read(this.model.blueprint, this);
		}

		if (this.value === 0)
			this.dissipate(true);
	}

	get key () { return this.id.no; }

	get fading () { return this.model.fading }

	add (value, visual) {

		this.value += value;

		if (this.model.key === 54) {
			let mana = Math.floor(this.value / 3);
			if (mana > 0) {
				this.value -= mana * 3;
				if (!visual)
					this.master.addMana(mana);
			}
		}

		if (this.value === 0)
			this.dissipate(true);
	}

	update () {

		if (this.fading === "start") {
			this.value--;
			if (this.value <= 0 || this.model.once)
				this.dissipate(true);
		}
	}

	endturnUpdate () {

		if (this.fading === "end") {
			this.value--;
			if (this.value <= 0 || this.model.once)
				this.dissipate(true);
		}
	}

	dissipate (silent=false) {

		if (this.dissipated)
			return;
		this.entity.statuses = this.entity.statuses.filter(status => status !== this);
		if (this.passives)
			this.passives.forEach(p => p());
		this.dissipated = true;
		if (!silent)
			this.master.notify("dissipatestatus", this);
	}

	deactivate () {

	}

	serialize () {

		return {
			key: this.key,
			model: this.model.key,
			value: this.value,
			dissipated: this.dissipated
		}
	}

	static build (master, entity, src) {

		let status = new Status(master, src.model, entity, src.value);
		status.entity = entity;
		status.dissipated = src.dissipated;
		return status;
	}
}